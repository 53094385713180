import axios from '../../utils/axios';
import styles from '../../styles/news.module.scss';
import React from 'react';
import { Language } from '../../utils/lang';
import cookie from 'react-cookies';
import { SRLWrapper } from 'simple-react-lightbox';

export default class News extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            data: [],
        };
    }
    componentDidMount() {
        axios
            .get(`/news?_sort=newsCreated&_order=DESC`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    data: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, data } = this.state;
        const lang = cookie.load('language');

        const GetNewsList = () =>
            data.length > 0
                ? data.map((n) => (
                      <div className={styles.news_element} key={n.id}>
                          <div className={styles.news_element_title}>
                              <p>
                                  {lang === 'pl'
                                      ? n.newsTitle_pl
                                      : n.newsTitle_en}
                              </p>
                              <p className={styles.news_element_date}>
                                  {Language.add_date}
                                  {new Date(n.newsCreated).toLocaleDateString()}
                              </p>
                          </div>
                          <div className={styles.news_wrapper}>
                              <div
                                  className={styles.news_wrapper_desc}
                                  dangerouslySetInnerHTML={{
                                      __html:
                                          lang === 'pl'
                                              ? n.newsDescription_pl
                                              : n.newsDescription_en,
                                  }}
                              ></div>
                              {n.images.length > 0 ? (
                                  <div className={styles.news_wrapper_images}>
                                      <SRLWrapper>
                                          {n.images.map((i) => (
                                              <img
                                                  src={i.path}
                                                  alt=''
                                                  key={i.id}
                                                  className={
                                                      n.images.length <= 1
                                                          ? styles.news_big_foto
                                                          : null ||
                                                            n.images.length ===
                                                                2
                                                          ? styles.news_medium_foto
                                                          : null
                                                  }
                                              />
                                          ))}
                                      </SRLWrapper>
                                  </div>
                              ) : null}
                          </div>
                      </div>
                  ))
                : null;

        if (error) {
            return (
                <div className='container flex justify-center items-center'>
                    {' '}
                    <span>Error: {error}</span>{' '}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className='container flex justify-center items-center'>
                    <div className='loading'>Loading...</div>
                </div>
            );
        } else {
            return (
                <>
                    <div>
                        <div className={styles.title}>{Language.news}</div>
                        <GetNewsList />
                    </div>
                </>
            );
        }
    }
}
