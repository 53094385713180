import axios from '../../utils/axios';
import styles from '../../styles/litters.module.scss';
import React from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import cookie from 'react-cookies';
import { Language } from '../../utils/lang';

export default class LittersDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            litter: [],
        };
    }
    componentDidMount() {
        let { id } = this.props.match.params;

        console.log(this.props.match);

        axios
            .get(`/litters/${id}`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    litter: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, litter } = this.state;
        const lang = cookie.load('language');

        const GetLitterDetails = () =>
            litter.length <= 0 ? (
                ''
            ) : (
                <div className={styles.litter_details_container}>
                    <div className={styles.litter_details_wrapper}>
                        <div className={styles.litter_details_name}>
                            {litter.litterName}
                        </div>
                        <div className={styles.litter_details_birth}>
                            {new Date(litter.litterBirth).toLocaleDateString()}
                        </div>
                    </div>

                    <div className={styles.litter_details_desc}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    lang === 'pl'
                                        ? litter.litterDescription_pl
                                        : litter.litterDescription_en,
                            }}
                        ></div>

                        {litter.images.filter((i) => i.field === 'mainImg')
                            .length > 0 ? (
                            <div>
                                <img
                                    src={
                                        litter.images.filter(
                                            (i) => i.field === 'mainImg'
                                        ).path
                                    }
                                    alt=''
                                />
                            </div>
                        ) : null}
                    </div>

                    {litter.images.filter((i) => i.field === 'gallery').length >
                    0 ? (
                        <div className={styles.litter_gallery}>
                            <div className={styles.litter_gallery_title}>
                                {Language.gallery}
                            </div>

                            <div className={styles.litter_gallery_images}>
                                <SRLWrapper>
                                    {litter.images
                                        .filter((im) => im.field === 'gallery')
                                        .map((image) => (
                                            <a href={image.path} key={image.id}>
                                                <img src={image.path} alt='' />
                                            </a>
                                        ))}
                                </SRLWrapper>
                            </div>
                        </div>
                    ) : null}
                </div>
            );

        if (error) {
            return (
                <div className='container flex justify-center items-center'>
                    {' '}
                    <span>Error: {error}</span>{' '}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className='container flex justify-center items-center'>
                    <div className='loading'>Loading...</div>
                </div>
            );
        } else {
            return (
                <>
                    <GetLitterDetails />
                </>
            );
        }
    }
}
