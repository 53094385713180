import React from 'react';
import axios from '../../utils/axios';
import styles from '../../styles/dogs.module.scss';
import { SRLWrapper } from 'simple-react-lightbox';
import { Language } from '../../utils/lang';
import cookie from 'react-cookies';

export default class DogsDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            dog: [],
        };
    }
    componentDidMount() {
        let { id } = this.props.match.params;

        axios
            .get(`/dogs/${id}`)
            .then((res) => {
                this.setState({
                    isLoading: true,
                    dog: res.data,
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: true,
                    error: error.message,
                });
            });
    }

    render() {
        const { isLoading, error, dog } = this.state;
        const lang = cookie.load('language');

        if (error) {
            return (
                <div className='container flex justify-center items-center'>
                    {' '}
                    <span>Error: {error}</span>{' '}
                </div>
            );
        } else if (!isLoading) {
            return (
                <div className='container flex justify-center items-center'>
                    <div className='loading'>Loading...</div>
                </div>
            );
        } else {
            return (
                <div className={styles.dog_container}>
                    <div className={styles.dog_wrapper_name}>
                        <div className={styles.dog_name}>{dog.dogName}</div>
                        <div className={styles.dog_wrapper_desc}>
                            <div className={styles.dog_img}>
                                <img
                                    src={
                                        dog.images.filter(
                                            (i) => i.field === 'mainImg'
                                        )[0].path
                                    }
                                    alt=''
                                />
                            </div>
                            <div
                                className={styles.dog_desc}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        lang === 'pl'
                                            ? dog.dogDescription_pl
                                            : dog.dogDescription_en,
                                }}
                            ></div>
                        </div>
                    </div>

                    {dog.images.filter((i) => i.field === 'pedigree').length >
                    0 ? (
                        <div className={styles.dog_pedigree}>
                            <div>{Language.pedigree}</div>

                            <SRLWrapper>
                                <a
                                    href={
                                        dog.images.filter(
                                            (i) => i.field === 'pedigree'
                                        ).path
                                    }
                                >
                                    <img
                                        src={
                                            dog.images.filter(
                                                (i) => i.field === 'pedigree'
                                            ).path
                                        }
                                        alt=''
                                    />
                                </a>
                            </SRLWrapper>
                        </div>
                    ) : null}

                    {dog.images.filter((i) => i.field === 'gallery').length >=
                    1 ? (
                        <div className={styles.dog_gallery}>
                            <div className={styles.dog_gallery_title}>
                                {Language.gallery}
                            </div>

                            <div className={styles.dog_gallery_images}>
                                <SRLWrapper>
                                    {dog.images
                                        .filter((i) => i.field === 'gallery')
                                        .map((im) => (
                                            <a href={im.path} key={im.id}>
                                                <img
                                                    key={im.id}
                                                    src={im.path}
                                                    alt=''
                                                />
                                            </a>
                                        ))}
                                </SRLWrapper>
                            </div>
                        </div>
                    ) : null}
                </div>
            );
        }
    }
}
