import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';

import Header from './components/header';
import Footer from './components/footer';

//import views
import News from './pages/news/index';

import DogsCategory from './pages/dogs/category';
import DogsList from './pages/dogs/list';
import DogsDetails from './pages/dogs/details';

import LittersCategory from './pages/litters/category';
import LittersList from './pages/litters/list.js';
import LittersDetails from './pages/litters/details.js';

import Plans from './pages/plans/index';

import Contact from './pages/contact/index';

import Hotel from './pages/hotel/index';

import Grooming from './pages/grooming/index';

import './styles/globals.scss';
import cookie from 'react-cookies';
import { BrowserRouter } from 'react-router-dom';

function App() {
    return (
        <>
            <BrowserRouter>
                <Header />

                <Switch>
                    <Route path='/' exact component={News}></Route>

                    <Route path='/news' exact component={News} />

                    <Route path='/dogs' exact component={DogsCategory} />
                    <Route path='/dogs/:breed' exact component={DogsList} />
                    <Route
                        path='/dogs/:breed/:id'
                        exact
                        component={DogsDetails}
                    />

                    <Route path='/litters' exact component={LittersCategory} />
                    <Route
                        path='/litters/:breed'
                        exact
                        component={LittersList}
                    />
                    <Route
                        path='/litters/:breed/:id'
                        exact
                        component={LittersDetails}
                    />

                    <Route path='/plans' exact component={Plans} />

                    <Route path='/contact' exact component={Contact} />

                    <Route path='/hotel' exact component={Hotel} />

                    <Route path='/grooming' exact component={Grooming} />
                </Switch>

                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
