import React, { useState } from 'react';
import styles from '../styles/header.module.scss';
import background from '../assets/images/mainPage.jpg';
import { NavLink } from 'react-router-dom';
import cookies from 'react-cookies';
import pl_flag from '../assets/images/flags/pl.svg';
import en_flag from '../assets/images/flags/en.svg';
import hamburger from '../assets/images/hamburger.png';
import close from '../assets/images/close.png';
import { Language } from '../utils/lang';

export default function Header() {
    const [toggle, setToggle] = useState(false);

    const changeLanguageToPL = () => {
        cookies.remove('language');
        cookies.save('language', 'pl', { path: '/' });
        window.location.reload();
    };
    const changeLanguageToEN = () => {
        cookies.remove('language');
        cookies.save('language', 'en', { path: '/' });
        window.location.reload();
    };

    return (
        <>
            <div
                className={
                    toggle
                        ? `${styles.mobile_nav} ${styles.mobile_nav_active}`
                        : styles.mobile_nav
                }
            >
                <div
                    className={styles.mobile_nav_close}
                    onClick={() => setToggle(false)}
                >
                    <img src={close} alt='clse nav' />
                </div>

                <nav>
                    <ul>
                        <li>
                            <NavLink
                                to='/news'
                                onClick={() => setToggle(false)}
                            >
                                {Language.nav_news}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/dogs'
                                onClick={() => setToggle(false)}
                            >
                                {Language.nav_dogs}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/litters'
                                onClick={() => setToggle(false)}
                            >
                                {Language.nav_litter}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/plans'
                                onClick={() => setToggle(false)}
                            >
                                {Language.nav_plans}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/hotel'
                                onClick={() => setToggle(false)}
                            >
                                {Language.nav_hotel}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/grooming'
                                onClick={() => setToggle(false)}
                            >
                                {Language.nav_grooming}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to='/contact'
                                onClick={() => setToggle(false)}
                            >
                                {Language.nav_contact}
                            </NavLink>
                        </li>
                        <li>
                            <div className={styles.nav_lang}>
                                <div
                                    className={styles.nav_lang_flag}
                                    onClick={changeLanguageToPL}
                                >
                                    <img src={pl_flag} />
                                </div>
                                <div
                                    className={styles.nav_lang_flag}
                                    onClick={changeLanguageToEN}
                                >
                                    <img src={en_flag} />
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>

            <div className={styles.container}>
                <div
                    className={styles.mobile_hamburger}
                    onClick={() => setToggle(true)}
                >
                    <img src={hamburger} alt='Open Menu' />
                </div>

                <nav className={styles.nav}>
                    <ul>
                        <li>
                            <NavLink to='/news'>{Language.nav_news}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/dogs'>{Language.nav_dogs}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/litters'>
                                {Language.nav_litter}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/plans'>{Language.nav_plans}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/hotel'>{Language.nav_hotel}</NavLink>
                        </li>
                        <li>
                            <NavLink to='/grooming'>
                                {Language.nav_grooming}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/contact'>
                                {Language.nav_contact}
                            </NavLink>
                        </li>
                        <li>
                            <div className={styles.nav_lang}>
                                <div
                                    className={styles.nav_lang_flag}
                                    onClick={changeLanguageToPL}
                                >
                                    <img src={pl_flag} />
                                </div>
                                <div
                                    className={styles.nav_lang_flag}
                                    onClick={changeLanguageToEN}
                                >
                                    <img src={en_flag} />
                                </div>
                            </div>
                        </li>
                    </ul>
                </nav>
                <img src={background} alt='' />
            </div>
        </>
    );
}
